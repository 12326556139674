import { Link } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC, ReactElement } from 'react'
import Button from '../../Button'

export type SplitActionSectionItemProps = {
  id: string
  title: string
  description: string
  image?: IGatsbyImageData
  reverseOrder: boolean
  color?: 'red' | 'blue' | 'yellow' | 'green'
  href: string
}
const SplitActionSectionItem: FC<SplitActionSectionItemProps> = ({
  id,
  description,
  title,
  image,
  href,
  reverseOrder = false,
  color = 'red'
}) => (
  <div className="container mx-auto px-4 lg:px-16 items-center flex flex-col-reverse lg:flex-row my-16">
    <div className="lg:w-3/5">
      <div
        className={`text-center lg:text-left ${
          reverseOrder ? 'lg:pl-16 xl:pl-28' : 'lg:pr-16 xl:pr-28'
        }`}
      >
        <h3 className="mb-4 text-2xl lg:text-3xl font-semibold font-display leading-relaxed">
          {title}
        </h3>
        <p className="mb-4 text-lg lg:text-xl font-light font-body leading-relaxed">
          {description}
        </p>
        <Link to={href}>
          <Button color={color} size="default" secondary>
            Learn more
          </Button>
        </Link>
      </div>
    </div>
    <div
      className={`mt-10 lg:mt-0 w-full lg:w-2/5 ${reverseOrder && `order-last lg:order-first`}`}
    >
      <GatsbyImage className="mb-4" alt={title} image={getImage(image)} />
    </div>
  </div>
)

export default SplitActionSectionItem
