import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC, ReactElement } from 'react'
import SplitActionSectionItem, {
  SplitActionSectionItemProps as SplitActionSectionItemProps
} from './SplitSectionItem'

export type SplitActionSectionProps = {
  id: string
}
const config: SplitActionSectionItemProps[] = [
  {
    id: 'homeWhat',
    title: 'WHAT is eduScrum?',
    description:
      'Imagine students who are engaged and ready to learn! eduScrum will encourage student participation and involve them in the learning process. Discover what eduScrum can do for you.',
    reverseOrder: false,
    color: 'blue',
    href: '/discover-eduscrum#what-is-eduscrum'
  },
  {
    id: 'homeWhy',
    title: 'Discover benefits: WHY eduScrum',
    description:
      'When you know WHY you want to do something you will do it with more skill and care. Let us explain why eduScrum can be beneficial for students and for teachers who want the best for their students. The naturally curious student will be ready to take on innovative challenges and the dedicated teacher will finally have an opportunity to challenge their students beyond literacy and numeracy.',
    reverseOrder: true,
    color: 'yellow',
    href: '/discover-eduscrum#why-eduscrum'
  },
  {
    id: 'homeHow',
    title: 'Check out HOW it works',
    description:
      'We will guide and explain briefly the basics of eduScrum to give you an idea of how it works.',
    reverseOrder: false,
    color: 'green',
    href:'/discover-eduscrum#how-does-it-work'
  }
]

const SplitActionSection: FC<SplitActionSectionProps> = ({ id }) => {
  const images: IGatsbyImageData[] = useStaticQuery(graphql`
    query SplitSectionActionImagesQuery {
      homeWhat: file(relativePath: { eq: "homeWhat.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      homeWhy: file(relativePath: { eq: "homeWhy.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      homeHow: file(relativePath: { eq: "homeHow.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const configWithImages = config.map((z: SplitActionSectionItemProps) => ({
    ...z,
    image: images[z.id]
  }))
  return (
    <section id={id} className="pb-20">
      {configWithImages.map((i) => (
        <SplitActionSectionItem {...i}  key={i.id}/>
      ))}
    </section>
  )
}

export default SplitActionSection
