import Layout from '../components/layout/Layout'
import SplitActionSection from '../components/sections/SplitActionSection/SplitSection'
import HeroSection from '../components/HeroSection'
import SEO from '../components/SEO'

const Index = () => (
  <Layout>
    <SEO
      title="Help your students thrive"
      description="Discover how eduScrum can guide students using a unique framework for 21st Century skill development and more!
      "
      reverseTitle
    />
    <div className="container mx-auto px-8 flex flex-col-reverse lg:flex-row">
      <HeroSection id="hero-section" />
    </div>
    <div id="splitSectionHomeActionAnchor" />
    <SplitActionSection id="splitSectionHomeAction" />
  </Layout>
)

export default Index
