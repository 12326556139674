import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Button from './Button'
import GradientText from './GradientText'
import HeroMain from '../svg/homeMain.svg'
import HeroIpad from '../svg/heroIpad.svg'

type HeroSectionProps = {
  id: string
}
const HeroSection: FC<HeroSectionProps> = ({ id }) => {
  const images: Record<string, IGatsbyImageData> = useStaticQuery(graphql`
    query HeroSectionImageQuery {
      heroMobile: file(relativePath: { eq: "heroMobile.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  return (
    <section
      id={id}
      className="my-auto lg:px-16 pt-10 pb-20 md:pb-40 xl:pb-60 xl:pt-20 2xl:pt-40 text-center md:relative w-full mb-10"
    >
      <div className="flex relative md:hidden flex-row justify-between max-h-56">
        <GatsbyImage
          className="mb-4 h-56 mx-auto"
          objectFit="scale-down"
          alt="dancingPerson1"
          image={getImage(images.heroMobile)}
        />
      </div>
      <div className="relative max-w-md mx-auto z-10">
        <h1 className="text-5xl xl:text-6xl font-bold font-display leading-relaxed mb-4 lg:mb-8">
          Help your students <GradientText>thrive</GradientText>
        </h1>
        <p className="text-lg lg:text-xl max-w-screen-sm mx-auto font-body font-light leading-relaxed">
        Discover how eduScrum can guide students using a unique framework for 21st Century skill development and more!
        </p>
        <p className="mt-8 md:mt-12 mb-8">
          <AnchorLink href="#splitSectionHomeActionAnchor">
            <Button size="lg">Get Started</Button>
          </AnchorLink>
        </p>
      </div>
      <div className="hidden xl:flex absolute left-1/2 transform -translate-x-1/2 top-20 2xl:top-40 xl:inset-center">
        <HeroMain />
      </div>
      <div className="hidden md:flex xl:hidden absolute left-1/2 transform -translate-x-1/2 top-0 2xl:top-40 xl:inset-center">
        <HeroIpad />
      </div>
    </section>
  )
}

export default HeroSection
